import React from "react";
import Footer from "../Footer";
import Header from "../Header";

const Layout = ({ children, isCLI }) => {
  return (
    <div className={"site-inner"}>
      <Header isCli={isCLI} />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;

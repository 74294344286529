import React from "react";

export interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <footer>
      <section className="bg-dark-02 footer-main lite">
        <div className="grid-100">
          <div>
            <div className="subsection">
              <p className="copyright-policy">
                ©Copyright 2013-{(new Date().getFullYear())}, LoginRadius
              </p>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;

import React from "react";
import { withPrefix } from 'gatsby';
import Helmet from "react-helmet";

const SEO = ({ title, description, slug }) => {
  const defaultTitle = "Loginradius Opensource Projects";
  const defaultDescription =
    "We believe that all developers are part of one big family. To share what we’ve learned with other developers, we’ve listed some of our open source projects here. Please join us in developing these projects further—let’s see where we can take this!";
  return (
    <>
      <Helmet htmlAttributes={{
        lang: "en",
        title: title || defaultTitle
      }}>
        <title>{title || defaultTitle}</title>
        <meta name="description" content={description || defaultDescription} />
        <meta property="og:title" content={title || defaultTitle} />
        <meta
          property="og:description"
          content={description || defaultDescription}
        />
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="shortcut icon" href={withPrefix("/favicon.ico")} />
        <link
          rel="stylesheet"
          type="text/css"
          media="screen"
          defer="true"
          href="https://www.loginradius.com/wp-content/themes/login-radius/style.css?ver=1671598874"
        />
      </Helmet>
    </>
  );
};

export default SEO;

import { graphql } from "gatsby";
import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";

import "./opensource.scss";

const OpenSourcePage = ({ data: { mdx } }) => {
  const { body, frontmatter } = mdx;
  return (
    <>
    <SEO title={frontmatter.title} description={frontmatter.description}/>
    <Layout>
      <div className="opensource">
        <section class="bg-light-01 ">
          <div class="grid-100">
            <div class=" margin-bottom--lg margin-top--lg">
              <MDXRenderer>{body}</MDXRenderer>
            </div>
          </div>
        </section>
      </div>
    </Layout>
    </>
  );
};

export const pageQuery = graphql`
  query OpenSourceBySlug($slug: String!) {
    mdx(slug: { eq: $slug }, frontmatter: { isOpenSource: { eq: true } }) {
      body
      headings {
        depth
        value
      }
      frontmatter {
        title
        description
      }
    }
  }
`;

export default OpenSourcePage;
